.appinfo-vcenter {
  display: flex;
  justify-content: left;
  align-items: center;
}

.appinfo-link {
  text-decoration: none;
  color: #00897b;
  font-weight: 500;
}
